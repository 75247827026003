import { template as template_ea78a5a14c2044da98c9a6f9eb2ec36a } from "@ember/template-compiler";
const FKControlMenuContainer = template_ea78a5a14c2044da98c9a6f9eb2ec36a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
