import { template as template_1f1245d2122642b6a1be74af466d9603 } from "@ember/template-compiler";
const SidebarSectionMessage = template_1f1245d2122642b6a1be74af466d9603(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
