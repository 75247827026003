import { template as template_70a9db7c55f84ed8958e08a9de6b32f7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_70a9db7c55f84ed8958e08a9de6b32f7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
