import { template as template_0c8703f0be234423844e52afb43d65ad } from "@ember/template-compiler";
const FKLabel = template_0c8703f0be234423844e52afb43d65ad(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
